.footer {
    padding: 55px 0;
    position: relative;
    background: #CC710D;

    &__title {
        position: relative;
        z-index: 10;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: #FEF6E4;
        margin-bottom: 32px;
    }
    &__holder-btns {
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @media ( max-width: 767px ) {
            flex-direction: column;
        }
    }
    &__fake-bg{
        height: 690px;
        width: 100%;
        position: absolute;
        top: -63px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background: url('../../public/static/images/footer-bg.png') top center no-repeat;
    }
    &__top-detail{
        height: 41px;
        width: 100%;
        position: absolute;
        top: -40px ;
        left: 0;
        z-index: 1;
        background: url('../../public/static/images/botton-t.png') top center no-repeat;
    }

}

.btn {
    max-width: 255px;
    width: 100%;

    margin: 0 9px 15px 9px;
    padding: 24px 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    line-height: 0;
    color: #FDF8E5;
    background-color: #BC3A8A;
    border-radius: 10px;

    @media ( max-width: 767px ) { margin-bottom: 10px; }

    @media ( min-width: 768px ) {
        font-size: 2vw;
        padding: 19px 0;
    }
    @media ( min-width: 824px ) {
        font-size: 18px;
        padding: 24px 0;
    }
}