.featured {
    padding-top: 69px;
    padding-bottom: 45px;
    background-color: #FEF6E4;

    &__title {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        color: #1A4B22;
        margin-bottom: 12px;

        @media ( min-width: 602px ) { font-size: 3vw; }
        @media ( min-width: 930px ) { font-size: 24px; }
    }

    &__text, &__text-strong {
        font-size: 18px;
        color: #302E31;
        margin-bottom: 27px;

        @media ( min-width: 602px ) { font-size: 2.1vw; }
        @media ( min-width: 930px ) { font-size: 18px; }
    }

    &__text-strong {
        font-weight: bold;
        margin-bottom: 0;
    }

    &__holder-image {
        position: relative;
        border: 10px solid #EF3D2D;

        &:after {
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            left: 28px;
            top: 100%;
            border-top: 0 solid transparent;
            border-bottom: 44px solid transparent;
            border-left: 50px solid #EF3D2D;
            transform: translateY( 10px );
            content: '';
        }

        .ratiohd { padding-bottom: 55.5%; }
    }
}