.hero {
    padding: 73px 0 75px;
    background: url('../../public/static/images/bg-hero.jpg') repeat-x center top;
}

.ratiohd {
	max-width: 732px;
    width: 100%;
	height: 0;
    position: relative;
	padding-bottom: 37.8%; /* (9 / 16) * 100 */
}

.ratio_el {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}