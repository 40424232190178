.info{
    max-width: 600px;
    margin: 0 auto 40px auto;
    padding: 20px 10px;
    border-radius: 10px;
    background: url('../../public/static/images/textura.png') #FFFFFF top center;
}

.info__slider{
    position: relative;
    margin-bottom: 20px;
    padding: 15% 3% 11% 3%;
}

.info__slider-bg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.info__content{
    font-size: 18px;
    color: #000000;
    p{
        margin-bottom: 20px;
    }
}

.info__content-title{
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    span{
        color: #BC3A8A
    }
}
@media (min-width: 768px){
    .info{
        padding: 40px 30px;
    }
    .info__slider{
        margin-bottom: 50px;
    }
}

@media (min-width: 1024px){

    .info{
        max-width: 1190px;
        margin-bottom: 140px;
        padding: 65px 70px 65px 90px;
        display: flex;
        align-items: center;
        gap: 40px;
        border-radius: 58px;
    }

    .info__slider{
        width: 50%;
        margin-bottom: 0;
        padding: 0 0 0 0;
        flex-grow: 1;
    }

    .info__content{
        width: 50%;
        flex-grow: 1;
    }

    .info__content-title{
        font-size: 24px;
        margin-bottom: 12px;
    }
 
}
