.carousel {
    max-width: 1190px;
    margin: 0 auto;

    &__item { height: 100%; }

    &__holder {
        div > button[data-direction=left],
        div > button[data-direction=right] {
            outline: none;
            height: 100%;
            background: url(https://cdn.dsmcdn.com/web/production/slick-arrow.svg) 50% no-repeat rgba($color: #fff, $alpha: .5);
            padding-left: 16px;
            padding-right: 16px;
            border: none;
        }
        div > button[data-direction=left] {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }

}

.styles-module_item-provider__YgMwz { cursor: default !important; } 

.owl-carousel {
    .owl-stage{ display: flex;}
    .owl-nav {
        margin-top: 0;

        button.owl-prev, button.owl-next {
            outline: none;
            height: 100%;
            padding-left: 16px !important;
            padding-right: 16px !important;
            position: absolute;
            top: 0;
        }

        button.owl-prev {
            left: 0;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        button.owl-next { right: 0; }
    }
}