.card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #FFFFFF;

    &__body{
        padding: 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__picture {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
    }

    &__icon {
        width: auto !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__title {
        margin-bottom: 5px;
        font-size: 19px;
        font-weight: bold;
    }

    &__nickname {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #CC710D;
    }
    
    &__nickname:hover {
        color: #bb680e;
    }

    &__text {
        margin-bottom: 20px;
        flex-grow: 1;
        font-size: 16px;
        font-weight: 300;
        color: #000;
    }

}