.carousel-info {
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;

    &__holder {
        div > button[data-direction=left],
        div > button[data-direction=right] {
            outline: none;
            height: 100%;
            padding-left: 16px;
            padding-right: 16px;
            border: none;
        }
        div > button[data-direction=left] {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }

    .owl-nav {
        margin-top: 0;

        button.owl-prev, 
        button.owl-next {
            height: 100%;
            padding-left: 16px !important;
            padding-right: 16px !important;
            position: absolute;
            top: 0;
            background: url('../../public/static/images/arrow.png') 50% no-repeat;
            background-size: 10px !important;
            font-size: 0;
            outline: none;
        }

        button.owl-prev {
            left: 0;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        button.owl-next { right: 0; }

        button.owl-prev:hover, 
        button.owl-next:hover {
            background: url('../../public/static/images/arrow.png') 50% no-repeat;
            background-color: transparent;
        }

    }

    .owl-carousel .owl-nav button.owl-next {
        right: -38px;
    }

    .owl-carousel .owl-nav button.owl-prev {
        left: -38px;
        transform: rotate(180deg);
    }

    .owl-dots{
        margin-top: 5px !important;
    }

}