@font-face {
    font-family: 'FoundersGroteskText';
    src: url('../public/static/fonts/FoundersGroteskText-Bold.woff2') format('woff2'),
        url('../public/static/fonts/FoundersGroteskText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FoundersGroteskText';
    src: url('../public/static/fonts/FoundersGroteskText-Light.woff2') format('woff2'),
        url('../public/static/fonts/FoundersGroteskText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


* {
    padding: 0;
    margin: 0; 
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility; 
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

img {
    display: block;
    max-width: 100%;
    border: 0; 
}

iframe { border: 0; }

/* ==================== HTML 5 ===================== */
article, aside, details, figcaption, figure, footer,
header, hgroup, main, nav, section, summary, picture {
    display: block; 
}

figure {
    margin: 0;
}

/* ==================== FORMULÁRIO ===================== */
input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a {
    text-decoration: none;
    background-color: transparent;
}

a:hover, a:focus {
    color: #fff;
    text-decoration: none;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

a:active, a:hover { outline: 0; }

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 1;
}

b, strong {
    font-weight: bold; 
}

li { list-style: none; }


/* ==================== GRID ===================== */
$maxWidth: 1190px;
$gutter: 20px;

.container {
    max-width: $maxWidth;
    padding-left: calc( $gutter / 2 );
    padding-right: calc( $gutter / 2 );
    margin-left: auto;
    margin-right: auto;
}
.container-fluid {
    padding-left: calc( $gutter / 2 );
    padding-right: calc( $gutter / 2 );
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.row.-items-center { align-items: center; }

.col {
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    position: relative;
}


/* Width */
.col-1 { width: 8.33333% !important; }
.col-2 { width: 16.66667% !important; }
.col-3 { width: 25% !important; }
.col-4 { width: 33.33333% !important; }
.col-5 { width: 41.66667% !important; }
.col-6 { width: 50% !important; }
.col-7 { width: 58.33333% !important; }
.col-8 { width: 66.66667% !important; }
.col-9 { width: 75% !important; }
.col-10 { width: 83.33333% !important; }
.col-11 { width: 91.66667% !important; }
.col-12 { width: 100% !important; }

@media ( min-width: 466px ) {
    .bp466\:col-8 { width: 66.66667% !important; }
}
@media ( min-width: 602px ) {
    .bp602\:col-4 { width: 33.33333% !important; }
    .bp602\:col-3 { width: 25% !important; }
    .bp602\:col-8 { width: 66.66667% !important; }
}
@media ( min-width: 640px ) {
    .bp640\:col-4 { width: 33.33333% !important; }
    .bp640\:col-3 { width: 25% !important; }
}
@media ( min-width: 992px ) {
    .bp992\:col-6 { width: 50% !important; }
}


.offset-1 { margin-left: 8.33333% !important; }
.offset-2 { margin-left: 16.66667% !important; }
.offset-3 { margin-left: 25% !important; }
.offset-4 { margin-left: 33.33333% !important; }
.offset-5 { margin-left: 41.66667% !important; }
.offset-6 { margin-left: 50% !important; }
.offset-7 { margin-left: 58.33333% !important; }
.offset-8 { margin-left: 66.66667% !important; }
.offset-9 { margin-left: 75% !important; }
.offset-10 { margin-left: 83.33333% !important; }
.offset-11 { margin-left: 91.66667% !important; }
.offset-12 { margin-left: 100% !important; }

@media ( min-width: 466px ) {
    .bp466\:offset-2 { margin-left: 16.66667% !important; }
}
@media ( min-width: 640px ) {
    .bp640\:offset-0 { margin-left: 0 !important; }
}


.push-1 { left: 8.33333% !important; }
.push-2 { left: 16.66667% !important; }
.push-3 { left: 25% !important; }
.push-4 { left: 33.33333% !important; }
.push-5 { left: 41.66667% !important; }
.push-6 { left: 50% !important; }
.push-7 { left: 58.33333% !important; }
.push-8 { left: 66.66667% !important; }
.push-9 { left: 75% !important; }
.push-10 { left: 83.33333% !important; }
.push-11 { left: 91.66667% !important; }
.push-12 { left: 100% !important; }


.pull-1 { right: 8.33333% !important; }
.pull-2 { right: 16.66667% !important; }
.pull-3 { right: 25% !important; }
.pull-4 { right: 33.33333% !important; }
.pull-5 { right: 41.66667% !important; }
.pull-6 { right: 50% !important; }
.pull-7 { right: 58.33333% !important; }
.pull-8 { right: 66.66667% !important; }
.pull-9 { right: 75% !important; }
.pull-10 { right: 83.33333% !important; }
.pull-11 { right: 91.66667% !important; }
.pull-12 { right: 100% !important; }


/* ==================== GENERAL ===================== */
body { 
    font-family: 'FoundersGroteskText';
    background: url('../public/static/images/textura.png') #CC710D top center;
}


/* ==================== COMPONENTS ===================== */
// card
@import url(./components/card.scss);


.participantes{
    background: #BC3A8A url(../public/static/images/textura.png) top center;
    padding-top: 50px;
    padding-bottom: 40px;
}

.participantes__title{
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

@media (min-width: 768px){

    .participantes{
        padding-top: 60px;
        padding-bottom: 100px;
    }

    .participantes__title {
        font-size: 28px;
        margin-bottom: 40px;
    }

}

.participantes .owl-nav [class*=owl-]:hover {
    background-color: transparent !important;
    color: #FFF;
    text-decoration: none;
}

.participantes .owl-dots {
    margin-top: 24px !important;
}

.participantes .owl-carousel .owl-nav button.owl-next {
    right: -36px;
}

.participantes .owl-carousel .owl-nav button.owl-prev {
    left: -36px;
    transform: rotate(180deg);
}

.participantes button.owl-prev, 
.participantes button.owl-next {
    background: url('../public/static/images/arrow.png') 50% no-repeat !important;
    background-size: 15px !important;
    transition:all .25s ease-in-out;
}


.participantes button.owl-prev:hover, 
.participantes button.owl-next:hover {
    background-size: 17px !important;
    transition:all .15s ease-in-out;
}


.participantes button.owl-prev span, 
.participantes button.owl-next span {
    display: none;
}

/* ==================== READ MORE ===================== */

.read-more{
    font-size: 14px;
    font-weight: 700;
    color: #CC710D;
    cursor: pointer;
}
.read-more:hover{
    font-size: 14px;
    font-weight: 700;
    color: #b8650c;
}


/* ==================== UTILITIES ===================== */
.center-block {
    margin-left: auto;
    margin-right: auto;
}

.d-flex { display: flex; }

.mx-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.px-22 {
    padding-left: 22px;
    padding-right: 22px;
}
.py-22 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.m-b-5 { margin-bottom: 5px; }
.m-b-15 { margin-bottom: 15px; }
.m-b-60 { margin-bottom: 60px; }

@media ( min-width: 602px ) {
    .bp602\:m-b-0 { margin-bottom: 0; }
}
@media ( min-width: 640px ) {
    .bp640\:m-b-0 { margin-bottom: 0; }
}

.fw-light { font-weight: 300; }
.fw-bold { font-weight: bold; }

.fs-16 { font-size: 16px; }
.fs-20 { font-size: 20px; }

.color-snow-100 { color: #FEF6E4; }
.color-green-100 { color: #1A4B22; }
.color-red-100 { color: #EF3D2D; }

.bg-red-100 { background-color: #EF3D2D; }