.videos {
    padding: 48px 0 46px 0;
    position: relative;
    background: #F1CD00;

    &__title {
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        color: #000000;
        margin-bottom: 52px;
    }

    &__card-title {
        font-size: 20px;
        font-weight: bold;
        color: #FEF6E4;

        @media ( min-width: 640px ) { font-size: 2vw; }
        @media ( min-width: 992px ) { font-size: 20px; }
    }

    &__top-detail{
        height: 41px;
        width: 100%;
        position: absolute;
        top: -14px;
        left: 0;
        z-index: 1;
        background: url('../../public/static/images/video-top.png') top center no-repeat;
    }

}