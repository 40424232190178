.header {
   
    background: url(../../public/static/images/header-bg.png) no-repeat top center;
    background-size: contain;

    @media(min-width: 768px){
        background-size: auto;
    }


    .spacer{
        
    }

    &__logo {
        padding-top: 20%;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        
        @media(min-width: 768px){ 
            padding-top: 260px;
            margin-bottom: 65px;
        }

        img{
            width: 161px;
            @media ( min-width: 512px ) { 
                width: auto;
                
            }
        }
   
    }

}