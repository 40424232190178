.modal__title {
    display: flex;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 10px;
}

.modal__close {
    position: absolute;
    top: 7px;
    right: 8px;
    cursor: pointer;
}